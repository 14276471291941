//----------------------------------------------
// Search box
// ---------------------------------------------

##{$appPrefix}-search-box-wrapper {
  position: relative;
}

##{$appPrefix}-search-input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 3rem;
  background: $layer-02;
  border: none;
  border-bottom: 1px solid $toggle-off;
  outline: 2px solid transparent;
  outline-offset: -2px;
  padding: 0 4.5rem 0 1rem;
  font-size: 1rem;
  line-height: 2.875rem;

  &:focus {
    outline-color: $button-primary;
  }

  &::-ms-clear {
    display: none;
  }
}

.sk-search-box__action {
  display: block;
  position: absolute;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  width: 2rem;
  background: transparent;
  border: 0;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }
}

.sk-search-box__loader {
  display: none;
}

.sk-search-box__clear {
  display: block;
  position: absolute;
  top: 0;
  right: 2.5rem;
  bottom: 0;
  width: 2rem;
  padding: 0;

  a {
    display: block;
    text-align: center;
    line-height: 3rem;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  svg {
    vertical-align: sub;
    color: $icon-secondary;
  }
}

##{$appPrefix}-search-input:hover + .sk-search-box__clear,
##{$appPrefix}-search-input:focus + .sk-search-box__clear,
.sk-search-box__clear:hover,
.sk-search-box__clear:active {
  opacity: 1;
}

// right to left layout support
[dir="rtl"] {
  ##{$appPrefix}-search-input {
    padding: 0 1rem 0 4.5rem;
  }

  .sk-search-box__action {
    left: 0.5rem;
    right: auto;
  }

  .sk-search-box__clear {
    left: 2.5rem;
    right: auto;
  }
}

//----------------------------------------------
// Typeahead
// ---------------------------------------------

.#{$appPrefix}-search-typeahead-container {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.5);

  li {
    line-height: 3rem;
    padding: 0 1rem;
    border-bottom: 1px solid $layer-01;
    background: $layer-02;

    &.#{$appPrefix}-active {
      background: $layer-01;
    }
  }
}

//----------------------------------------------
// Search bar
// ---------------------------------------------

.#{$appPrefix}--search-bar {
  .#{config.$prefix}--grid .#{config.$prefix}--row [class^="#{config.$prefix}--col"] {
    padding: 0;
  }

  #search-tips-modal {
    padding-left: 1rem;
    line-height: 1.2rem;
    text-align: left;
  }
}
