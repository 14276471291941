//----------------------------------------------
// Tabs
// ---------------------------------------------

.#{$appPrefix}--tabs.is-disabled {
  display: none
}

.#{$appPrefix}--tabs__item {
  display: block;
  background: $layer-01;
  .#{$appPrefix}--tabs-option__text {
    @include type.type-style('body-compact-02');
    display: block;
    margin: 0 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid $layer-accent-01;
    color: $text-secondary;
  }
  &:hover {
    background: $background-hover;
    text-decoration: none;
    .#{$appPrefix}--tabs-option__text {
      color: $text-primary;
    }
  }
  &.is-active {
    .#{$appPrefix}--tabs-option__text {
      color: $text-primary;
      font-weight: 600;
    }
  }
  &.is-disabled {
    cursor: initial;
    .#{$appPrefix}--tabs-option__text {
      color: $text-disabled;
    }
  }
}

// TODO: Tabs count is not yet implemented (.#{$appPrefix}--tabs-option__count)

// Large breakpoints
@include grid.breakpoint('lg') {
  .#{$appPrefix}--tabs {
    white-space: nowrap;
  }

  .#{$appPrefix}--tabs__item {
    display: inline-block;
    background: colors.$gray-20;
    transition: background-color 100ms cubic-bezier(0.2, 0, 0.38, 0.9);
    .#{$appPrefix}--tabs-option__text {
      border-top: rem(2px) solid transparent;
      border-bottom: none;
      margin: 0;
      line-height: 2rem;
      font-size: 14px;
      padding: 0.5rem 2rem 0.5rem 1rem;
      color: $text-secondary;
      transition: color 100ms cubic-bezier(0.2, 0, 0.38, 0.9), border-top-color 100ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 100ms cubic-bezier(0.2, 0, 0.38, 0.9);
    }
    &:hover {
      background: $background-selected-hover;
    }
    &.is-active {
      .#{$appPrefix}--tabs-option__text {
        border-color: $button-primary;
        background: $layer-02;
        color: $text-primary;
      }
    }
    &:focus {
      outline: 2px solid $button-primary;
      outline-offset: -2px;
    }
    &.is-disabled {
      cursor: initial;
      .#{$appPrefix}--tabs-option__text {
        border-color: $disabled-02;
        color: $text-disabled;
      }
    }
    & + .#{$appPrefix}--tabs__item {
      box-shadow: -1px 0 0 0 $toggle-off;
    }
    &.is-active,
    &.is-active + .#{$appPrefix}--tabs__item {
      box-shadow: none;
    }
  }
}

// Large breakpoints
@include grid.breakpoint('xlg') {
  .#{$appPrefix}--tabs__item {
    .#{$appPrefix}--tabs-option__text {
      padding: 0.5rem 3rem 0.5rem 1rem;
    }
  }
}

//----------------------------------------------
// Showhide widget
// ---------------------------------------------

// Showhide title (shows or hides tabs when clicked)
.#{$appPrefix}--showhide__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  border-bottom: 1px solid $toggle-off;
  padding: 0 rem(13px) 0 1rem;
  background: $layer-01;
  cursor: pointer;
  transition: color 0.3s ease, background 0.3s ease;
  &:hover {
    background: $background-hover;
  }
}

// Show tabs by default (small & medium breakpoints)
.#{$appPrefix}--showhide__content {
  display: block;
  // Hide tabs when widget is collpased
  &.is-collapsed {
    display: none;
  }
}

// If JS is not supported, hide title and show tabs
.no-js {
  .#{$appPrefix}--showhide__title {
    display: none;
  }
  .#{$appPrefix}--showhide__content.is-collapsed {
    display: block;
  }
}

// Medium breakpoint (floating showhide content)
@include grid.breakpoint('md') {
  .#{$appPrefix}--showhide__content {
    box-shadow: 0 rem(4px) rem(8px) 0 rgba(0, 0, 0, 0.1);
  }
}

// Large breakpoints (Showhide is disabled, so hide title and show tabs)
@include grid.breakpoint('lg') {
  .#{$appPrefix}--showhide__title {
    display: none;
  }
  .#{$appPrefix}--showhide__content {
    box-shadow: none;
    &.is-collapsed {
      display: block;
    }
  }
}
