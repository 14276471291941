//----------------------------------------------
// Hits stats
// ---------------------------------------------

.#{$appPrefix}--content__results {
  .#{$appPrefix}--hits-stats {
    @include type.type-style('body-compact-02');
    margin-bottom: 2rem;
  }
}

//----------------------------------------------
// Sort widget
// ---------------------------------------------

.#{$appPrefix}--sort {
  position: relative;

  select {
    display: block;
    padding: 0 3rem 0 1rem;
    -webkit-appearance: none;
    appearance: none;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    height: 100%;
    border: none;
    border-radius: 0;
    background: transparent;
    cursor: pointer;

    &:hover {
      background: $background-hover;
    }

    &:focus {
      outline-color: $button-primary;
    }
  }

  .bx--select__arrow {
    will-change: transform;
    fill: #171717;
    position: absolute;
    right: 1rem;
    pointer-events: none;
  }
}

.#{$appPrefix}--download-image-tag {
  max-height: 0.75rem;
  padding: 0px 0.5rem;
  margin: 0;
}

//----------------------------------------------
// Result item
// ---------------------------------------------

.#{$appPrefix}--result-item {
  margin: 0 -1rem 1rem;
  a {
    display: block;
    padding: 0.5rem 1rem 0;
    text-decoration: none;
    strong {
      font-weight: 500;
    }
    h2 {
      @include type.type-style('fluid-heading-03');
      max-width: 40rem;
      margin-bottom: 0.5rem;
      color: $button-primary;
    }
    p {
      @include type.type-style('body-02');
      max-width: 40rem;
      padding-bottom: 0.5rem;
      color: $text-secondary;
      word-break: break-word;
    }
    p.#{$appPrefix}--result-item__category,
    p.#{$appPrefix}--result-item__date {
      font-size: 12px;
    }
    p.#{$appPrefix}--result-item__url {
      // @include type.type-style('body-compact-01');
      // For some reason, body-compact-01 is not working in k8s, so use the properties directly for now:
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.16px;
      line-height: 1.25rem;
      max-height: 1.75rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color:$text-helper;
    }
    .#{$appPrefix}--result-item__icon {
      vertical-align: middle;
      margin-right: 0.5rem;
      fill:$text-helper;
    }
    p.#{$appPrefix}--result-item__tags {
      .#{config.$prefix}--tag {
        margin: 0 0.25rem;
        padding: 0.5rem 1rem;
        cursor: pointer;

        &.#{config.$prefix}--tag--green {
          color: $text-secondary;

          svg {
            fill: $text-secondary;
            margin-right: 0.5rem;
          }
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
    &:hover {
      h2 {
        text-decoration: underline;
      }
      p {
        color: $text-primary;
      }
      .#{$appPrefix}--result-item__url,
      .#{$appPrefix}--result-item__date,
      .#{$appPrefix}--g2crowd {
        color: $text-secondary !important;
      }
      .#{$appPrefix}--result-item__icon {
        fill: $text-secondary;
      }
      p.#{$appPrefix}--result-item__tags {
        .#{config.$prefix}--tag.#{config.$prefix}--tag--green {
          color: $text-primary;

          svg {
            fill: $text-primary;
          }
        }
      }

      & + .#{$appPrefix}--result-item__versions {
        color: $text-primary;
      }
    }
  }
}
.#{$appPrefix}--result-item__versions {
  position: relative;
  margin: 0.125rem 1rem 0;
  max-width: 40rem;
  padding: 0.5rem 2.5rem 0.5rem 0;
  border-top: 1px solid colors.$gray-20;
  border-bottom: 1px solid colors.$gray-20;
  height: auto;
  overflow: hidden;
  color: $text-secondary;

  .content {
    line-height: 1.6rem;

    strong {
      font-weight: 500;
    }
  }

  .fc-versions-icon {
    display: none;
    position: absolute;
    align-items: center;
    padding: 0.75rem;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:hover {
      background-color: colors.$gray-10;;
    }
  }

  &.collapsible {
    .fc-versions-icon {
      display: flex;
    }
  }

  &.collapsed {
    height: 2.625rem;

    .fc-versions-icon svg {
      rotate: 180deg;
    }
  }

  &:hover {
    color: $text-primary;
  }
}

.#{$appPrefix}--result-sitelink {
  ul.#{$appPrefix}--result-sitelink__link {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: baseline;
    padding-bottom: 0.5rem;
    li {
      line-height: 1.5rem;
      color:$text-helper;
      a {
        padding-top: 0rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

//----------------------------------------------
// G2Crowd rating widget
// ---------------------------------------------

.#{$appPrefix}--g2crowd {
  @include type.type-scale(2);
  line-height: 1.125rem !important;
  padding-bottom: 0.5rem;
  color:$text-helper !important;
}

.#{$appPrefix}--g2crowd__rating {
  margin-right: 0.25rem;
  vertical-align: baseline;
  position: relative;
  top: rem(1px);
  svg {
    fill: $text-secondary;
  }
}

.#{$appPrefix}--g2crowd__star {
  margin: 0 1px;
}

.#{$appPrefix}--g2crowd__star--half {
  position: relative;
  display: inline-block;
  .#{config.$prefix}--star-half {
    position: absolute;
    left: 0;
    right: 0;
  }
}

//----------------------------------------------
// Filter description ads (Support and Downloads)
// ---------------------------------------------

.#{$appPrefix}--filter-ad {
  max-width: 42rem;
  margin: 1rem -1rem;

  a {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;

    .#{$appPrefix}--filter-ad__cta {
      color: $button-primary;
    }

    &:hover .#{$appPrefix}--filter-ad__cta {
      color: $link-primary-hover;
      text-decoration: underline;
    }
  }

  .#{config.$prefix}--toast-notification {
    margin: 0;
    width: auto;
    box-shadow: none;
    border: 1px solid mix(
      colors.$blue-70,
      colors.$white-0,
      35%
    );
    border-left: 3px solid $support-info;
    &__title {
      @include type.type-style('heading-02');
    }
    &__subtitle {
      @include type.type-style('body-compact-02');
    }
    &__close-button {
      transition: background-color 100ms cubic-bezier(0.2, 0, 0.38, 0.9);

      svg {
        transition: fill 100ms cubic-bezier(0.2, 0, 0.38, 0.9);
      }

      &:hover {
        background: $link-primary-hover;

        svg {
          fill: $icon-on-color;
        }
      }
    }
  }
}

//----------------------------------------------
// Machine translation widget
// ---------------------------------------------

.#{$appPrefix}--machine-translation {
  margin-top: -1.5rem;
  margin-bottom: 2rem;

  &__toggle {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $layer-accent-01;

    .#{config.$prefix}--toggletip {
      margin-right: 1rem;
    }

    .#{config.$prefix}--toggletip-label {
      font-size: 0.875rem;
    }

    .#{config.$prefix}--toggle__label-text {
      display: none;
    }

    .#{config.$prefix}--toggle__switch {
      margin-top: 0;
    }
  }

  .#{config.$prefix}--inline-notification {
    margin: 2rem 0;
    max-width: none;
  }
}

//----------------------------------------------
// Tiled Search Results
// ---------------------------------------------

.#{$appPrefix}-hits-wrapper {
  .#{$bxPrefix}--card-group__cards__col {
    h3 {
      height: 84px;
    }
    p {
      height: 167px;
      overflow: hidden;
    }
  }
}
