//----------------------------------------------
// Default style for links
// ---------------------------------------------

a {
  text-decoration: none;
  color: $button-primary;
  &:hover {
    text-decoration: underline;
    color: $link-primary-hover;
  }
}

//----------------------------------------------
// Utility classes
// ---------------------------------------------

// Bold
.#{$appPrefix}--bold {
  font-weight: 600;
}

// Hidden content
.#{$appPrefix}--hide, .#{$appPrefix}--access {
  display: none !important;
}

//----------------------------------------------
// Use inherit font-size for lists, instead of small font size
// ---------------------------------------------

.#{config.$prefix}--list--nested,
.#{config.$prefix}--list--unordered,
.#{config.$prefix}--list--ordered {
  font-size: inherit;
  line-height: 1.5em;
}

//----------------------------------------------
// SVG close icon to use in pseudo elements
// ---------------------------------------------

$closeIcon: url("data:image/svg+xml; utf8, <svg focusable='false' preserveAspectRatio='xMidYMid meet' style='will-change: transform;' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16' aria-hidden='true'><path d='M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8z'></path></svg>");

//----------------------------------------------
// SVG chevron down icon to use in pseudo elements
// ---------------------------------------------

$ChevronDownIcon: url("data:image/svg+xml; utf8, <svg focusable='false' preserveAspectRatio='xMidYMid meet' style='will-change: transform;' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' aria-hidden='true'><path d='M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z'></path></svg>");

//----------------------------------------------
// Custom colors
// ---------------------------------------------

$selected-ui-custom: colors.$cool-gray-10;
$hover-ui-custom: #e6e8ec;

//----------------------------------------------
// Custom carbon icons (SVGs inside react wrappers)
// ---------------------------------------------

span[size] {
  display: inline-block;
}

span > svg {
  fill: currentColor;
}

.bx--link-with-icon span:nth-child(2) {
  flex: 0;
}
