//----------------------------------------------
// No hits display
// ---------------------------------------------

.#{$appPrefix}--no-hits {
  padding-bottom: 4rem;
  @include grid.breakpoint('lg') {
    padding-bottom: 3rem;
  }
  @include grid.breakpoint('xlg') {
    padding-bottom: 7rem;
  }

  // Main title
  h1 {
    @include type.type-style('fluid-heading-05', true);
    // Fix: Use light font weight in medium and large breakpoints,
    // since carbon type is not applying properly the fluid type sets
    @include grid.breakpoint('md') {
      font-weight: 300;
    }
    padding-top: 1rem;
  }

  // Search tips bullets
  .#{$appPrefix}--tips-bullets,
  // Check all results button
  .#{$appPrefix}--view-all {
    margin-top: 2rem;
  }

  // Limit text for main title and need help bullets
  h1,
  ul {
    max-width: 40rem;
  }

  // Line and paddings above categories and need help
  .#{$appPrefix}--categories,
  .#{$appPrefix}--need-help {
    margin-top: 4rem;
    border-top: 1px solid $layer-accent-01;
    padding-top: 2rem;
    @include grid.breakpoint('lg') {
      margin-top: 6rem;
    }

    // Secondary titles on left side
    h2 {
      @include type.type-style('heading-02');
      margin-bottom: 4rem;
      @include grid.breakpoint('lg') {
        // Break heading in 2 lines
        max-width: rem(140px);
      }
    }
  }

  // Ads
  .#{$appPrefix}--filter-ad {
    margin-bottom: 0;
    margin-top: 2rem;
  }

  // Categories (look at our product lines) section
  .#{$appPrefix}--categories {
    // Business & Technologies titles
    h3 {
      @include type.type-style('fluid-heading-04', true);
      margin-bottom: 1.5rem;
      a {
        color: $text-primary;
      }
    }
    &__business {
      // Add margin before business topics in small breakpoint
      margin-top: 4rem;
      @include grid.breakpoint('md') {
        // Remmove margin before business topics in medium and large breakpoints
        margin-top: 0;
      }
    }
    &__list {
      // Since each item has an inner padding, expand container to align content with title
      margin: 0 -1rem;
      a {
        @include type.type-style('body-compact-02');
        display: block;
        padding: 0.5rem 1rem;
        color: $text-primary;
        &:hover {
          background: $layer-01;
          text-decoration: none;
        }
      }
    }
    // View all link
    &__view-all {
      padding: 0.5rem 0;
      a {
        svg {
          fill: $button-primary;
          vertical-align: text-bottom;
          margin-left: 0.2rem;
        }
        &:hover {
          svg {
            fill: $link-primary-hover;
          }
        }
      }
    }
  }

  .#{config.$prefix}--list--unordered {
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  // Re-eanble padding in columns since no-hits is now inside of a condensed grid
  [class|="#{config.$prefix}--col"] {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .#{$appPrefix}--health-links {
    margin: 1rem 0 0 1rem;
    border-top: 1px solid colors.$warm-gray-30;
    padding-top: 1rem;

    a {
      display: inline-block;
      margin-bottom: 1rem;

      span {
        white-space: nowrap;
      }

      svg {
        vertical-align: bottom;
        fill: $link-primary;
      }

      &:visited {
        svg {
          fill: $link-visited;
        }
      }

      &:hover {
        svg {
          fill: $link-primary-hover;
        }
      }
    }

    @include grid.breakpoint('md') {
      column-count: 2;
      column-gap: 2rem;
    }
  }

  // New no hits layout
  .#{$appPrefix}--no-matches {
    margin: 0;
    &__main {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
      h1 {
        @include type.type-style('fluid-heading-04', true);
        margin: 0.5rem 0 3rem;
        padding: 0;
      }

      .#{$appPrefix}--view-all {
        margin-top: 0;
        margin-bottom: 3rem;
      }

      h2 {
        @include type.type-style('heading-02', true);
        margin-bottom: 1rem;
      }

      .search-tips {
        margin-bottom: 3rem;
        max-width: 40rem;
        padding: 1rem 2rem 1rem 1rem;
        background: $layer-01;

        ul {
          @include grid.breakpoint('md') {
            column-count: 2;
            column-gap: 2rem;
          }

          li {
            margin-left: 1.75rem;
            padding-bottom: 0.25rem;
            line-height: 1.5rem;

            &::before {
              content: url("data:image/svg+xml; utf8, <svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='20' height='20' viewBox='0 0 20 20' aria-hidden='true'><path stroke-width='0.5' d='M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z'></path></svg>");
              display: inline-block;
              text-indent: -1.75rem;
              vertical-align: middle;
              position: relative;
              top: 2px;
            }
          }
        }
      }

      .alerts {
        margin-top: -0.5rem;
        margin-bottom: 2.5rem;

        &:empty {
          display: none;
        }

        .#{$appPrefix}--filter-ad {
            margin: 0 -1rem 1rem;
        }
      }

      .top-searches {
        margin-bottom: 3rem;

        h2 {
          margin-bottom: 0.5rem;
        }

        .tags {
          margin: 0 -0.25rem;

          a {
            display: inline-block;
            margin: 0 0.25rem;
          }
        }

        .#{config.$prefix}--tag {
          margin: 0;
          padding: 0.5rem 1rem;
          cursor: pointer;
        }
      }
    }

    &__help {
      .#{$appPrefix}--no-hits__cards {
        margin: 0;
      }
    }
  }

  &__cards {
    margin: 0 1rem;

    .support,
    .products {
      margin: 0.5rem 0 2rem;
    }

    .#{$bxPrefix}--card {
      border: 1px solid #e4e4e4;
      background: #fff;

      &__wrapper::before {
        padding-top: 33%;
      }

      &__heading {
        @include type.type-style('heading-02', true);
        margin-bottom: 0.75rem;

        img {
          vertical-align: middle;
          margin: -0.5rem 0.5rem -0.5rem -0.5rem;
          padding: 0.5rem;
        }
      }

      &__copy {
        margin-bottom: 0.5rem!important;
      }

      &__footer {
        align-items: center;
      }

      &__cta svg {
        width: 16px;
        height: 16px;
      }
    }

    @include grid.breakpoint('lg') {
      margin: 0 0 0 1rem;

      .support,
      .products {
        margin: 0.5rem 1rem 2rem 0;
      }
    }
  }
}

.#{$appPrefix}--content--no-hits {
  @include grid.breakpoint('md') {
    margin-bottom: 3rem;
  }
}

// Narrow layout
.#{$appPrefix}--no-hits--narrow {
  .#{$appPrefix}--categories,
  .#{$appPrefix}--need-help {
    // In narrow layout, secondary titles don't break into 2 lines
    h2 {
      max-width: none;
    }
  }
}

//----------------------------------------------
// No hits error
// ---------------------------------------------

.#{$appPrefix}--no-hits__error {
  p {
    margin-bottom: 1rem;
    text-align: center;
    font-size: rem(20px);
    line-height: rem(30px);
    color: $text-secondary;
  }
  .#{$appPrefix}--no-hits__ibm-logo {
    margin: 2rem 0;
  }
}

//----------------------------------------------
// Hide hits container for the initial loading
// ---------------------------------------------

.#{$appPrefix}--content--is-initial-loading {
  .#{$appPrefix}--hits {
    display: none;
  }
}

//----------------------------------------------
// If not in fake facets, expand results area to show full no hits page
// ---------------------------------------------

.#{$appPrefix}--content--no-hits:not(.#{$appPrefix}--content--fake-facets) {
  .#{$appPrefix}--hits-stats,
  .#{$appPrefix}--filters__selected,
  .#{$appPrefix}--content__sidebar,
  .#{$appPrefix}--content__gutter {
    display: none;
  }

  .#{$appPrefix}--content__results {
    flex: auto;
    max-width: none;
  }
}

//----------------------------------------------
// If fake facets are displayed, hide duplicated no hits cards
// ---------------------------------------------

.#{$appPrefix}--content--no-hits.#{$appPrefix}--content--fake-facets {
  .#{$appPrefix}--no-hits {
    padding-bottom: 0;
  }

  .#{$appPrefix}--no-matches__help {
    display: none;
  }

  .#{$appPrefix}--no-matches__main {
    flex: auto;
    max-width: none;
  }
}
