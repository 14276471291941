//----------------------------------------------
// Advertisements
// ---------------------------------------------

.#{$appPrefix}--search-advertisement {
  margin-bottom: 1rem;
  max-width: 40rem;

  &__wrapper {
    padding: .5rem 1rem 0;

    a {
      padding: 0;
    }
  }

  a {
    .#{$appPrefix}--search-advertisement__cta {
      color: $button-primary;
    }

    &:hover .#{$appPrefix}--search-advertisement__cta {
      color: $link-primary-hover;
      text-decoration: underline;
    }
  }

  .#{config.$prefix}--inline-notification {
    margin: 0;
    width: auto;
    max-width: none;
    box-shadow: none;
    border: 1px solid mix(
      colors.$yellow-30,
      colors.$white-0,
      35%
    );
    border-left: 3px solid $support-warning;

    &__text-wrapper {
      padding: 0.9375rem 0;
      flex-direction: column;
    }

    &__title {
      @include type.type-style('heading-02');
    }
    &__subtitle {
      @include type.type-style('body-compact-02');
    }

    &__close-button {
      transition: background-color 100ms cubic-bezier(0.2, 0, 0.38, 0.9);

      &:hover {
        background: $support-warning;
      }
    }

    &__action-button {
      display: none;
    }
  }
}
