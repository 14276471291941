//----------------------------------------------
// Right to left layout support
// ---------------------------------------------

[dir="rtl"] {
  // Carbon buttons
  .#{config.$prefix}--btn {
    padding-left: 63px;
    padding-right: 15px;
    text-align: right;
  }

  // Carbon cards - CTA icon
  .#{config.$prefix}--card__footer svg {
    transform: scaleX(-1);
  }

  // Masthead
  // ---------------------------------------------

  .#{config.$prefix}--header__search--actions {
    left: 0;
    right: auto;
  }

  .#{config.$prefix}--header__nav-content {
    left: auto;
    right: 0;
  }

  .#{config.$prefix}--header__menu-arrow,
  .#{config.$prefix}--link-with-icon svg {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  .#{config.$prefix}--link-with-icon svg {
    transform: scaleX(-1);
  }

  .#{config.$prefix}--masthead__search {
    margin-right: 3rem;
    margin-left: 0;
  }

  .#{config.$prefix}--masthead__search.#{config.$prefix}--masthead__search--active {
    margin-right: 0;
  }

  .react-autosuggest__suggestion .#{config.$prefix}--container-class {
    display: block;
    line-height: 3rem;
  }

  @include grid.breakpoint-down(md) {
    .#{$appPrefix}--products-page .#{config.$prefix}--masthead__search.#{config.$prefix}--masthead__search--active {
      left: -3rem;
      right: auto;
    }

    .#{$appPrefix}--search-page .#{config.$prefix}--masthead__search.#{config.$prefix}--masthead__search--active {
      left: 0;
      right: auto;
    }
  }

  // search-tips.scss
  // ---------------------------------------------

  // Search tips link
  .#{$appPrefix}--tips__link {
    text-align: left;
  }

  // Search tips modal
  .#{config.$prefix}--modal-close {
    left: 0;
    right: auto;
  }

  // filters.scss
  // ---------------------------------------------

  // Filter item (Support & Downloads)
  .#{$appPrefix}--facet-filters__item a {
    padding: rem(5px) 1rem rem(5px) 3rem;
    &.is-active:after {
      left: rem(8px);
      right: auto;
    }
  }

  // Hierarchical Refinement List (Business & Technologies)
  .#{$hierarchicalOptions} a {
    padding: rem(5px) 1rem rem(5px) 3rem;
    &.is-selected.is-leaf:after {
      left: rem(8px);
      right: auto;
    }
  }
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-right: 2rem;
  }
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-right: 3rem;
  }
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-right: 4rem;
  }
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-right: 5rem;
  }

  // List Option checkbox (Products and Learning tabs)
  .#{$listOption}__text {
    padding-right: rem(26px); //width of checkbox 16px + 10px of padding
    padding-left: 0;
    &::before {
      right: 0;
      left: auto;
    }
    &::after {
      right: rem(5px);
      left: auto;
    }
  }
  // Checkbox Focus
  .#{$listOption}__checkbox:focus + .#{$listOption}__text::before,
  .#{$listOption}__checkbox:checked:focus + .#{$listOption}__text::before,
  .#{$listOption}__checkbox:indeterminate:focus + .#{$listOption}__text::before {
    right: -2px;
    left: auto;
  }

  // Filters overlay plus icon
  .#{$appPrefix}--filters-overlay__link-icon {
    float: left;
  }

  // Selected filters
  .sk-selected-filters a,
  .#{$appPrefix}--filters__selected .sk-selected-filters + .sk-reset-filters {
    float: right;
  }

  // Toast notifications
  .#{config.$prefix}--actionable-notification {
    padding-left: 0;
    padding-right: 0.875rem;

    &__icon {
      margin-left: 0.875rem;
      margin-right: 0;
    }

    &__details {
      margin-left: 1rem;
      margin-right: 0;
    }
  }

  // results.scss
  // ---------------------------------------------

  .#{$appPrefix}--filter-ad {
    .#{config.$prefix}--toast-notification {
      border-left: 1px solid mix(
        colors.$blue-70,
        colors.$white-0,
        35%
      );
      border-right: 3px solid $support-info;
    }
  }


  // advertisement.scss
  // ---------------------------------------------

  .#{$appPrefix}--search-advertisement {
    .#{config.$prefix}--actionable-notification {
      border-left: 1px solid mix(
        colors.$yellow-30,
        colors.$white-0,
        35%
      );
      border-right: 3px solid $support-warning;
    }
  }

  // no-hits.scss
  // ---------------------------------------------

  // View all arrow
  .#{$appPrefix}--no-hits .#{$appPrefix}--categories__view-all a svg {
    transform: rotate(180deg);
  }

  // List item
  .#{config.$prefix}--list__item {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0;
    padding-left: 0;
    &::before {
      right: -1rem;
      left: auto;
    }
  }

  // pagination.scss
  // ---------------------------------------------

  .#{config.$prefix}--pagination-nav {
    // Previous and next buttons
    &__page--direction {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .#{$appPrefix}--pagesize {
    border-left: 1px solid $layer-accent-01;
    border-right: none;
    margin-left: 1rem;
    margin-right: 0;
    &:after {
      left: 1rem;
      right: auto;
    }
    select {
      padding: 0 1rem 0 2.5rem;
    }
  }

  // Footer
  // ---------------------------------------------

  .#{config.$prefix}--locale-btn__container .#{config.$prefix}--btn {
    &__icon {
      left: 1rem;
      right: auto;
    }
  }

  // Locale selector
  .#{config.$prefix}--locale-modal-container {
    .#{config.$prefix}--modal-header {
      padding-left: 3rem;
      padding-right: 2rem;
    }

    .#{config.$prefix}--modal-close {
      left: 1rem;
      right: auto;
    }

    .#{config.$prefix}--locale-modal__label-globe {
      margin-left: 0;
      margin-right: 0.25rem;
    }

    .#{config.$prefix}--locale-modal__label-arrow {
      margin-left: 0.5rem;
      margin-right: 0;
      transform: scaleX(-1);
    }

    .#{config.$prefix}--search--xl {
      .#{config.$prefix}--search-input {
        padding: 0 3rem 0 4rem;
      }

      .#{config.$prefix}--search-magnifier {
        left: auto;
        right: 1.5rem;
        transform: translate(50%, -50%);
      }
    }
  }

  @include grid.breakpoint('md') {
    .#{config.$prefix}--footer--short .#{config.$prefix}--locale-btn__container {
      margin-left: auto;
      margin-right: 25%;
    }

    .#{config.$prefix}--locale-modal-container {
      .#{config.$prefix}--modal-header {
        padding-left: 20%;
        // padding-right: 2rem;
      }
    }
  }

  //----------------------------------------------
  // Large breakpoints
  // ---------------------------------------------

  @include grid.breakpoint('lg') {
    // layout.scss
    // ---------------------------------------------

    // Sidebar border
    .#{$appPrefix}--content__sidebar {
      border-right: 1px solid $layer-accent-01;
      border-left: none;

      &.#{$appPrefix}--all-tab {
        border-right: none;
      }
    }

    // tabs.scss
    // ---------------------------------------------
    .ibm--tabs__item {
      box-shadow: none !important;
      border-right: 1px solid #8d8d8d;
    }

    .ibm--tabs__item:first-child,
    .ibm--tabs__item.is-active,
    .ibm--tabs__item.is-active + .ibm--tabs__item {
      border-right: none;
    }

    // Label padding
    .#{$appPrefix}--tabs__item .#{$appPrefix}--tabs-option__text {
      padding: 0.5rem 1rem 0.5rem 3rem;
    }

    // Footer
    // ---------------------------------------------

    .#{config.$prefix}--footer-nav {
      margin-left: auto;
      margin-right: 12.5%;
    }

    .#{config.$prefix}--footer--short .#{config.$prefix}--locale-btn__container {
      margin-right: 62.5%;
    }

    .#{config.$prefix}--legal-nav__holder {
      margin-left: auto;
      margin-right: 25%;
    }
  }

  // test-products.scss
  // ---------------------------------------------

  .#{$appPrefix}--products-page {
    // Search box
    ##{$appPrefix}-search-input {
      padding: 0 3.5rem 0 3rem;
    }

    .sk-search-box__action {
      left: auto;
      right: 1rem;
      text-align: right;
    }

    .sk-search-box__clear {
      left: 1rem;
      right: auto;
    }

    // Filters
    .sk-panel__header,
    .sk-hierarchical-refinement-list__header,
    .#{$appPrefix}--facet-filters__title,
    .sk-item-list-option,
    .sk-refinement-list__view-more-action,
    .sk-hierarchical-refinement-list__view-more-action,
    .#{$hierarchicalOptions} a {
      padding-right: 2.5rem;
    }

    // Extra padding on the right for nested filters
    .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
      padding-right: 3.5rem;
    }
    .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
      padding-right: 4.5rem;
    }
    .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
      padding-right: 5.5rem;
    }
    .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
      padding-right: 6.5rem;
    }

    // Results
    .#{$appPrefix}--products-list {
      .#{$bxPrefix}--card-group__cards__col {
        @media (min-width: 42rem) and (max-width: 1055px) {
          border-left: 0;
          border-right: 1px solid $layer-active-01;

          &:nth-child(2n + 1) {
            border-right: 0;
          }
        }

        @include grid.breakpoint('lg') {
          border-left: 0;
          border-right: 1px solid $layer-active-01;

          &:nth-child(3n + 1) {
            border-right: 0;
          }
        }
      }
    }

    // Contact module
    // @include theme($g100, true) {
      .#{$appPrefix}--help {
        &__container #call-ibm #call-ibm-data {
          left: auto;
          right: 1rem;
        }

        &__card {
          @media (min-width: 42rem) and (max-width: 1055px) {
            border-left: 0;
            border-right: 1px solid $layer-active-01;

            &:nth-child(2n + 1) {
              border-right: 0;
            }
          }

          @include grid.breakpoint('lg') {
            border-left: 0;
            border-right: 1px solid $layer-active-01;

            &:nth-child(3n + 1) {
              border-right: 0;
            }
          }
        }
      }
    // }

    @include grid.breakpoint('lg') {
      ##{$appPrefix}-search-input {
        padding: 0 4rem 0 3rem;
      }

      ##{$appPrefix}--filters {
        margin: 0 -1.5rem 0 1rem;
        border-left: 1px solid $layer-active-01;
        border-right: 0;
      }

      .#{$appPrefix}--help-link {
        margin: 0 1.5rem 2rem 1rem;
      }
    }

    @include grid.breakpoint('xlg') {
      ##{$appPrefix}--filters {
        margin: 0 -1.5rem 0 -1rem;
      }
    }
  }

  // Machine translation (always EN/LTR content)
  .#{$appPrefix}--machine-translation {
    &__toggle {
      direction: ltr;
      justify-content: end;

      .#{config.$prefix}--toggle-input__label {
        margin-right: 4.625rem;
      }
    }

    &__notification {
      .#{config.$prefix}--inline-notification__icon {
        margin-left: 0.875rem;
        margin-right: 0;
      }
      .#{config.$prefix}--inline-notification__text-wrapper {
        direction: ltr;
      }
    }
  }
}


html[lang="ar-AE"], html[lang="ar-SA"] {
  .filter--technologyTopics,
  .filter--businessTopics,
  .#{$appPrefix}--no-hits .#{$appPrefix}--categories {
    display: none;
  }
}
