@charset "UTF-8";

//----------------------------------------------
// Import carbon and ibmdotcom dependencies
// ---------------------------------------------

// Configuration
@use '@carbon/react/scss/config' with
  ($css--default-type: true, $css--reset: true, $prefix: 'cds');
@use '@carbon/grid';
@use '@carbon/colors';
@use '@carbon/type';

@use '@carbon/react/scss/compat/themes' as compat;
@use '@carbon/react/scss/compat/theme' as * with (
  $theme: compat.$white
);


@use '@carbon/react/scss/components/button';
@use '@carbon/react/scss/components/tag';
@use '@carbon/react/scss/components/loading';
@use '@carbon/react/scss/components/pagination';
@use '@carbon/react/scss/components/pagination-nav';
@use '@carbon/react/scss/components/list';
@use '@carbon/react/scss/components/notification';
@use '@carbon/react/scss/components/toggle';
@use '@carbon/react/scss/components/toggletip';
@use '@carbon/react/scss/components/popover';
@use '@carbon/react/scss/components/modal';
@use '@carbon/react/scss/components/link';
@use '@carbon/react/scss/components/search';
@use '@carbon/react/scss/components/accordion';
@use '@carbon/react/scss/components/tooltip';

// @import '@carbon/styles/scss/components/popover';

@import '@carbon/ibmdotcom-styles/scss/components/footer';
@import '@carbon/ibmdotcom-styles/scss/components/card';
@import '@carbon/ibmdotcom-styles/scss/components/card-group/card-group';
@import '@carbon/ibmdotcom-styles/scss/components/card-link/card-link';
@import '@carbon/ibmdotcom-styles/scss/components/link-list';
@import '@carbon/ibmdotcom-styles/scss/components/link-with-icon/link-with-icon';
@import '@carbon/ibmdotcom-styles/scss/components/locale-modal/locale-modal';
@import '@carbon/ibmdotcom-styles/scss/components/cta-block';
@import '@carbon/ibmdotcom-styles/scss/components/ctasection';
@import '@carbon/ibmdotcom-styles/scss/components/buttongroup/buttongroup';
@import '@carbon/ibmdotcom-styles/scss/components/feature-card/feature-card';
@import '@carbon/ibmdotcom-styles/scss/components/card-section-simple/card-section-simple';

$appPrefix: 'ibm';
$bxPrefix: 'bx';

:root {
  @include theme();
}
@include grid.css-grid();
// @include dotcom-shell;

//----------------------------------------------
// Carbon update adjustments
// ---------------------------------------------

.bx--masthead__search {
  .cds--popover {
    display: none;
  }
}

.#{config.$prefix}--modal-container {
  background-color: white;;
}

.bx--header__search--input {
  background-color: white!important;
}

.#{$appPrefix}--product-box {
  .#{config.$prefix}--link-with-icon__container{
    .#{config.$prefix}--link-with-icon  {
      &.#{config.$prefix}--link {
        @include type.type-style('body-compact-02');
      }
      span {
        vertical-align: middle;
        align-self: center;
      }
    }
  }
}

// .#{$appPrefix}-content__results {
//   .#{$appPrefix}--categories {
//     h2 {
//       border-top: none;
//       padding-top: 0;
//     }
//   }
// }


//----------------------------------------------
// Import app styles
// ---------------------------------------------

@import 'functions.scss';
@import 'globals.scss';
@import 'layout.scss';
@import 'sticky-header.scss';
@import 'tabs.scss';
@import 'filters.scss';
@import 'results.scss';
@import 'advertisement.scss';
@import 'pagination.scss';
@import 'no-hits.scss';
@import 'search-tips.scss';
@import 'footer.scss';
@import 'rtl.scss';
@import 'search-bar.scss';
@import 'product-box.scss';
@import 'test-products.scss';


@import 'search-tips-nojs.scss';
@import 'no-carbon.scss';
@import 'home.scss';

// Remove block hiding links in navigation (embedded search)
.bx--header__nav .bx--header__menu-title[role='menuitem'][aria-expanded='true'] + .bx--header__menu li::before {
  display: none
}

.#{$appPrefix}--embedded {
  .#{$bxPrefix}--dotcom-shell {
    padding-top: 0;
    .#{$bxPrefix}--dotcom-shell__content {
      max-width: 100%;
    }
  }
}