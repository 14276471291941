.#{$appPrefix}--search-landing-page {

  $button-transition: background-color 110ms, border-color 110ms, color 110ms;
  $search-transition: cubic-bezier(0.2, 0, 0.38, 0.9);
  $search-transition-timing: 95ms;
  @import 'carbon-components/scss/components/ui-shell/header';
  @import '@carbon/ibmdotcom-styles/scss/components/masthead/masthead-search';

  @include carbon--breakpoint-down(md) {
    .#{$bxPrefix}--masthead__search.#{$bxPrefix}--masthead__search--active {
      position: static;
      width: 100%;
    }
  }

  dds-search-with-typeahead {
    visibility: hidden;
  }
  background: $layer-02;
  .#{$bxPrefix}--dotcom-shell__content {
    max-width: 100%;
  }
  .#{config.$prefix}--header__nav-container {
    display: initial!important;
  }
  .#{$appPrefix}--sticky-header {
    display: none;
  }
}
.#{config.$prefix}--dotcom-shell__content {
  max-width: inherit;
}
.#{$appPrefix}--home-content {
  padding: 0 1rem;
  .#{$appPrefix}--home-center-row {
    justify-content: center;
  }
  .#{$appPrefix}--home-heading {
    padding-top: 32px;
    text-align: center;
    padding-bottom: 64px;
    h1 {
      padding-bottom: 96px;
      // @include carbon--type-style("expressive-heading-05", true);
      @include type.type-style('fluid-heading-05', true);
    }
    .#{$bxPrefix}--header__search--input,
    .#{config.$prefix}--header__action {
      background: $layer-01!important;
      svg {
        fill: $icon-secondary;
      }
    }
    .react-autosuggest__suggestions-container {
      z-index: 999;
    }

    .#{$bxPrefix}--header__search--input {
      z-index: 0;
    }
  }
  .#{$appPrefix}--home-info {
      padding-top: 5%;
    // padding-bottom: 96px;
    // background: colors.$gray-10;
    h4 {
      @include type.type-style('heading-02');
      margin-bottom: 10px;
    }
    .#{config.$prefix}--list--unordered {
      margin-bottom: 48px;
    }

    .need-help {
      padding: 64px 0 48px 0;
    }

    .#{$appPrefix}--home-tags {
      a .#{config.$prefix}--tag {
        cursor: pointer;
      }
    }
  }
}
