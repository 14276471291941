//----------------------------------------------
// Sticky header
// ---------------------------------------------

// Disable sticky header for small breakpoint
.#{$appPrefix}--sticky-header__compensation {
  display: none;
}
.#{$appPrefix}--sticky-header.is-sticky .#{$appPrefix}--sticky-header__content {
  position: static;
}

// Medium & large breakpoints (enable sticky header)
@include grid.breakpoint('md') {
  .#{$appPrefix}--sticky-header.is-sticky {
    .#{$appPrefix}--sticky-header__compensation {
      display: block;
      height: 3.5rem !important;
    }

    .#{$appPrefix}--sticky-header__content {
      background: colors.$gray-10;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    }
  }
}

// Large breakpoint
@include grid.breakpoint('lg') {
  .#{$appPrefix}--sticky-header.is-sticky {
    .#{$appPrefix}--sticky-header__compensation {
      height: 4.125rem !important;
    }
  }
}
