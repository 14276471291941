//----------------------------------------------
// Search tips
// ---------------------------------------------

.#{$appPrefix}--tips {
  &__container {
    padding: 0.5rem 0 0;
    min-height: 2rem;
    > .#{$bxPrefix}--col {
      @include carbon--breakpoint(md) {
        padding-right: 0
      }
    }
  }
  &__link {
    @include type.type-style('body-compact-02');
    text-align: right;
  }
  &__modal {
    .#{config.$prefix}--modal {
      z-index: 99999;
    }
    .#{config.$prefix}--modal-header__heading {
      @include type.type-style('fluid-heading-04', true);
    }
  }
}

.#{$appPrefix}--tips__copy {
  h2 {
    @include type.type-style('fluid-heading-04', true);
    padding-bottom: 2rem;
  }

  h4 {
    @include type.type-style('body-02');
    font-weight: 600;
  }

  p {
    padding-bottom: 2rem;
    @include type.type-style('body-compact-02');
  }

  .#{config.$prefix}--list--unordered {
    padding-left: 1rem;
    padding-bottom: 2rem;
  }

  hr {
    margin: 2rem 0;
    border: none;
    border-top: 1px solid colors.$gray-30;
  }
}

//----------------------------------------------
// Style current tips content
// ---------------------------------------------

.ibm--tips__content {
  padding-top: 1rem;
  max-width: 30rem;

  #ibm-tips-title,
  #ibm-tips-title + .ibm-rule,
  > div + .ibm-rule,
  .ibm-link-list,
  .ibm-link-list + .ibm-rule,
  .ibm-access,
  .ibm-anchor-up-link {
    display: none;
  }

  h2,
  .ibm-h4 {
    @include type.type-scale(8);
    font-weight: 400;
    line-height: 125%;
  }

  h3,
  .ibm-h5 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 125%;
  }

  li,
  h2.smgraytext {
    @include type.type-style('body-02');
  }

  .ibm-rule {
    margin: 2rem 0;
    border-top: 1px solid colors.$gray-30;
    hr {
      display: none;
    }
  }

  h2.ibm-rule,
  h3.ibm-rule {
    padding-top: 2rem;
  }
}
