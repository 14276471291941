//----------------------------------------------
// Link list
// ---------------------------------------------

.#{config.$prefix}--link-list {
  &__heading {
    @include type.type-style('heading-02');
    margin-left: 0.5rem;
    margin-bottom: 1rem;
  }

  &__list--vertical-end {
    .#{config.$prefix}--link-list__list__CTA {
      border-bottom: 1px solid $layer-accent-01;
      max-width: 40rem;

      &:first-of-type {
        border-top: 1px solid $layer-accent-01;
      }

      .#{config.$prefix}--link-with-icon {
        display: flex;
        padding: 1rem 0.5rem;
        justify-content: space-between;

        &__container {
          display: block;
        }

        svg {
          min-width: 1.25rem;
          margin-left: 0.5rem;
          vertical-align: middle;
          align-self: center;
          fill: currentColor;
        }
      }
    }
  }
}

//----------------------------------------------
// Product box and Download box
// ---------------------------------------------

.#{$appPrefix}--product-box ,
.#{$appPrefix}--download-box {
  display: none;
  padding: 1rem 1rem 1.5rem;
  margin-right: 1rem;
  transition: background-color 500ms cubic-bezier(0.2, 0, 0.38, 0.9);

  &__pictogram {
    margin: -0.6rem -0.1rem;
    padding: 0.66rem;
  }

  &__all {
    padding-left: 0.5rem;
    padding-top: 1rem;
  }

  &__item-tags {
    display: inline-block;
    margin: -0.75rem 0 1rem 0.125rem;
    vertical-align: top;
  }

  .#{config.$prefix}--tag {
    padding: 0.5rem 1rem;
  }

  .#{config.$prefix}--link-list {
    margin-top: 1rem;

    li {
      word-break: break-word;
    }
  }

  &.#{$appPrefix}--alternate {
    background-color: colors.$cool-gray-10;
  }

  // Large & up breakpoints
  @include grid.breakpoint('lg') {
    display: block;
  }
}

//----------------------------------------------
// Download box
// ---------------------------------------------

.#{$appPrefix}--download-box {
  margin: 2rem 0 3rem;
  padding: 0;

  &__all {
    padding-left: 1rem;
  }

  &__item-tags {
    margin: -0.75rem 0 1rem 0.625rem;
  }

  .#{config.$prefix}--link-list {
    &__heading {
      margin-left: 0;
    }

    &__list--vertical-end {
      .#{config.$prefix}--link-list__list__CTA {
        border-left: 1px solid $layer-accent-01;
        border-right: 1px solid $layer-accent-01;
        .#{config.$prefix}--link-with-icon {
          @include type.type-style('body-compact-02');
          padding: 1rem;
          svg {
            align-self: start;
          }
        }
      }
    }
  }
}
