//----------------------------------------------
// Hits stats in sidebar
// ---------------------------------------------

$rowPrefix: 'bx';

.#{$appPrefix}--content__sidebar {
  .#{$appPrefix}--hits-stats {
    @include type.type-style('fluid-heading-04', true);
    border-bottom: 1px solid $layer-accent-01;
    padding: 0 1rem 2rem;
    color: $text-secondary;
    .#{$appPrefix}--hits-stats__count {
      display: block;
    }
  }
}

//----------------------------------------------
// Filters search
// ---------------------------------------------

.filter-options {
    .sk-item-list {
      height: auto;
      max-height: 225px;
      overflow-y: auto;
      margin-right: 0;
      &::-webkit-scrollbar {
          -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
          width: 11px;
      }

      &::-webkit-scrollbar:horizontal {
          height: 11px;
      }

      &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 2px solid white; /* should match background, can't be transparent */
          background-color: rgba(0, 0, 0, .5);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      }

    }


}

.filter-search {
  padding-left: 2.5rem;
  margin-bottom: 0.3125rem;
  height: 2rem;

  .#{config.$prefix}--search-input {
    height: 2rem;
  }
  .#{config.$prefix}--search-magnifier {
    position: absolute;
    left: 2.5rem;
  }
  .#{config.$prefix}--search-close {
    margin-right: 0;
  }
}

:not(.#{$appPrefix}--products-page) {
  .filter-search {
    margin-top: 0.3125rem;
    padding-left: 1rem;
    .#{config.$prefix}--search-magnifier {
      left: 1rem;
      position: absolute;
    }
  }
}

//----------------------------------------------
// Filters header
// ---------------------------------------------

.#{$appPrefix}--filters__header {
  padding: 0 1rem 2rem;
}

.#{$appPrefix}--filters__title {
  @include type.type-style('body-compact-02');
}

// Hide Filter by if Clear filters is visible
.sk-reset-filters:not(.is-disabled) + .#{$appPrefix}--filters__title,
.#{$appPrefix}--facet-filters__clear:not(.is-disabled) + .#{$appPrefix}--filters__title {
  display: none;
}


//----------------------------------------------
// Filter containers
// ---------------------------------------------

// Filters with checkboxes (Products and Learning tabs)
.sk-panel,
// Business & Technologies (Products)
.sk-hierarchical-refinement-list {
  margin: 2rem 0;
  padding-top: 1rem;
  border-top: 1px solid $layer-accent-01;
}

// Support and Download filters
.#{$appPrefix}--facet-filters__list {
  margin-bottom: 2rem;
}

// Hide line for first filter type
.#{$appPrefix}--filters__header + .sk-panel,
.#{$appPrefix}--filters__header + .sk-hierarchical-refinement-list {
  margin-top: 0;
  border-top: 0;
  padding-top: 0;
}

// Title for filters with checkboxes (Products and Learning tabs)
.sk-panel__header,
// Title for Business & Technologies (Products)
.sk-hierarchical-refinement-list__header,
// Title for Support and Download filters
.#{$appPrefix}--facet-filters__title {
  @include type.type-style('heading-02');
  margin-bottom: 0.5rem;
  padding: 0 1rem;
}

// Hide if there are no filters
// Filters with checkboxes (Products and Learning tabs)
.sk-panel.is-disabled,
// Business & Technologies (Products)
.sk-hierarchical-refinement-list.is-disabled {
  display: none;
}

//----------------------------------------------
// Hierarchical Refinement List (Business & Technologies)
// ---------------------------------------------

$hierarchicalOptions: 'sk-hierarchical-refinement-list__hierarchical-options';

.#{$hierarchicalOptions} {
  a {
    @include type.type-style('body-compact-02');
    display: block;
    position: relative;
    margin-bottom: 1px;
    // Add extra padding to the right to make room for the close icon
    padding: rem(5px) 3rem rem(5px) 1rem;
    color: $text-secondary;
    word-break: break-word;
    hyphens: auto;
    &.is-selected.is-leaf {
      background: $selected-ui-custom;
      color: $text-primary;
    }
    // If filter is selected, show close icon (only if is last in path)
    &.is-selected.is-leaf:after {
      content: $closeIcon;
      position: absolute;
      top: rem(6px);
      right: rem(8px);
    }
    &:hover,
    &.is-selected:hover {
      background: $hover-ui-custom;
      color: $text-primary;
      text-decoration: none;
    }
  }
}

// Extra padding on the left for nested filters
.#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
  padding-left: 2rem;
}
.#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
  padding-left: 3rem;
}
.#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
  padding-left: 4rem;
}
.#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
  padding-left: 5rem;
}

//----------------------------------------------
// View more
// ---------------------------------------------

// View more for filters with checkboxes (Products and Learning tabs)
.sk-refinement-list__view-more-action,
// View more for Business & Technologies (Products)
.sk-hierarchical-refinement-list__view-more-action {
  @include type.type-style('body-compact-02');
  margin-top: 0.5rem;
  padding: rem(5px) 1rem;
}

//----------------------------------------------
// List Option (filters with checkboxes in Products and Learning tabs)
// ---------------------------------------------

$listOption: 'sk-item-list-option';

.#{$listOption} {
  @include type.type-style('body-compact-02');
  margin-bottom: 1px;
  padding: rem(5px) 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  color: $text-secondary;
  cursor: pointer;
  &.is-active {
    background: $selected-ui-custom;
    color: $text-primary;
  }
  &:hover,
  &.is-active:hover {
    background: $hover-ui-custom;
    color: $text-primary;
    text-decoration: none;
  }
  &__text:after {
    width: 0.5375rem!important;
    height: 0.2875rem!important;
    margin-top: -0.375rem;
  }
}

//----------------------------------------------
// Checkbox
// ---------------------------------------------

// Hide native checkbox input
.#{$listOption}__checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
}

// Checkbox label
.#{$listOption}__text {
  position: relative;
  display: block;
  padding-left: rem(26px); //width of checkbox 16px + 10px of padding
  user-select: none;
  word-break: break-word;
  hyphens: auto;
}

// Recreate checkbox in the before pseudo-element
.#{$listOption}__text::before {
  box-sizing: border-box;
  content: '';
  width: rem(16px);
  height: rem(16px);
  margin: rem(2px);
  position: absolute;
  left: 0;
  top: rem(1px);
  background-color: transparent;
  border: 1px solid $border-inverse;
  border-radius: 1px;
}

// Recreate the appearance of the check in the after pseudo-element
.#{$listOption}__text::after {
  content: '';
  position: absolute;
  left: rem(6px);
  top: rem(7px);
  width: rem(7px);
  height: rem(3px);
  background: none;
  border-left: 2px solid $text-inverse;
  border-bottom: 2px solid $text-inverse;
  transform: scale(0) rotate(-45deg);
  transform-origin: bottom right;
  margin-top: rem(-3px);
}

//----------------------------------------------
// Checkbox checked
// ---------------------------------------------

// Update properties for checked checkbox
.#{$listOption}__checkbox:checked + .#{$listOption}__text::before,
.#{$listOption}__checkbox:indeterminate + .#{$listOption}__text::before,
.#{$listOption}__text[data-contained-checkbox-state='true']::before,
.#{$listOption}__text[data-contained-checkbox-state='mixed']::before {
  background-color: $icon-primary;
  border-color: $icon-primary;
  border-width: 1px;
}

// Check symbol
.#{$listOption}__checkbox:checked + .#{$listOption}__text::after,
.#{$listOption}__text[data-contained-checkbox-state='true']::after {
  transform: scale(1) rotate(-45deg);
}

// Indeterminate symbol
.#{$listOption}__checkbox:indeterminate + .#{$listOption}__text::after,
.#{$listOption}__text[data-contained-checkbox-state='mixed']::after {
  transform: scale(1) rotate(0deg);
  border-left: 0 solid $text-inverse;
  border-bottom: 2px solid $text-inverse;
  width: rem(8px);
  top: rem(10px);
}

//----------------------------------------------
// Checkbox focus indicator
// ---------------------------------------------

// Unchecked
.#{$listOption}__checkbox:focus + .#{$listOption}__text::before,
// Checked
.#{$listOption}__checkbox:checked:focus + .#{$listOption}__text::before,
// Indeterminate
.#{$listOption}__checkbox:indeterminate:focus + .#{$listOption}__text::before {
  // increase the width/height to fake an outline.
  border-color: $focus;
  border-width: 3px;
  width: rem(20px);
  height: rem(20px);
  left: -2px;
  top: 0;
}

//----------------------------------------------
// Checkbox disabled
// ---------------------------------------------

// Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
[disabled] ~ _ {
  font-size: inherit;
}

// Label
.#{$listOption}__checkbox:disabled + .#{$listOption}__text,
.#{$listOption}__text[data-contained-checkbox-disabled='true'] {
  cursor: not-allowed;
  color: $text-disabled;
}

// Checkbox
.#{$listOption}__checkbox:disabled + .#{$listOption}__text::before,
.#{$listOption}__text[data-contained-checkbox-disabled='true']::before {
  border-color: $text-disabled;
}

// Check & Indeterminate symbols
.#{$listOption}__checkbox:checked:disabled + .#{$listOption}__text::before,
.#{$listOption}__checkbox:indeterminate:disabled + .#{$listOption}__text::before,
.#{$listOption}__text[data-contained-checkbox-state='true'][data-contained-checkbox-disabled='true']::before,
.#{$listOption}__text[data-contained-checkbox-state='mixed'][data-contained-checkbox-disabled='true']::before {
  background-color: $text-disabled;
}

//----------------------------------------------
// Selected filters
// ---------------------------------------------

.#{$appPrefix}--filters__selected {
  &:after {
    content: ' ';
    display: block;
    clear: both;
  }
}

.sk-selected-filters {
  margin-left: rem(-6px);
  a {
    float: left;
    // Customize carbon tag (padding, height, background color and adds close icon)
    .#{config.$prefix}--tag {
      padding: 0.25rem 0.75rem;
      height: auto;
      &:after {
        content: $closeIcon;
        margin-left: 0.25rem;
        width: 1rem;
        height: 1rem;
        margin-top: -0.25rem;
      }
      &.#{config.$prefix}--tag--gray {
        background: $background-hover;
      }
    }
    &:hover {
      text-decoration: none;
      .#{config.$prefix}--tag--gray {
        background: colors.$cool-gray-20;
      }
    }
  }
}

//----------------------------------------------
// Reset filters
// ---------------------------------------------

.sk-reset-filters__reset {
  @include type.type-style('body-compact-02');
}

// Hide reset filters if no filters applied
.sk-reset-filters.is-disabled {
  display: none;
}

// Reset filters in Selected filters (Products, desktop view)
.#{$appPrefix}--filters__selected {
  .sk-reset-filters__reset {
    display: block;
    padding: rem(5px) 0;
  }
  .sk-selected-filters a:last-child {
    margin-right: 1rem;
  }
  .sk-selected-filters + .sk-reset-filters {
    float: left;
    margin-bottom: 2rem;
  }
}

// Reset filters in left navigation (Support & Downloads)
.#{$appPrefix}--facet-filters__clear {
  @include type.type-style('body-compact-02');
}

.#{$appPrefix}--facet-filters__clear.is-disabled {
  display: none;
}

//----------------------------------------------
// Facet filters (layout for Learning, Support & Download filters)
// ---------------------------------------------

// Top header (Filter by and clear filters)
.#{$appPrefix}--facet-filters__header {
  @include type.type-style('body-compact-02');
  margin: 2rem 0;
  padding: 1rem 1rem 0;
  border-top: 1px solid $layer-accent-01;
}

// Filter item (Support & Downloads)
.#{$appPrefix}--facet-filters__item {
  @include type.type-style('body-compact-02');
  margin-bottom: 1px;
  a {
    display: block;
    position: relative;
    // Add extra padding to the right to make room for the close icon
    padding: rem(5px) 3rem rem(5px) 1rem;
    color: $text-secondary;
    // Limit text container's width to avoid text lines too long
    span {
      display: inline-block;
      max-width: 15rem;
    }
    &.is-active {
      background: $selected-ui-custom;
      color: $text-primary;
    }
    // If filter is active (applied), show close icon
    &.is-active:after {
      content: $closeIcon;
      position: absolute;
      top: rem(6px);
      right: rem(8px);
    }
    &:hover,
    &.is-active:hover {
      background: $hover-ui-custom;
      color: $text-primary;
      text-decoration: none;
    }
  }
}

//----------------------------------------------
// Filters overlay
// ---------------------------------------------

.#{$appPrefix}--filters-overlay {
  // Filter by link
  &__link {
    margin: -1.5rem -1rem 1.5rem;
    border-bottom: 1px solid $layer-accent-01;
    padding: 0.5rem 0;
    line-height: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease, background 0.3s ease;
    &:hover {
      background: $background-hover;
    }
    &-label {
      margin: 0 auto;
      padding: 0 rem(13px) 0 1rem;
    }
    &-icon {
      float: right;
      margin-top: 0.1rem;
    }
  }

  // Overlay title (Filter by)
  &__header {
    @include type.type-style('fluid-heading-04');
    padding: 0 1rem 2rem !important;
  }

  // Overlay footer
  &__footer {
    position: absolute;
    background: $layer-02;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 3rem;
    padding: 0 1rem;

    // Clear all filters (mimic carbon button)
    .sk-reset-filters a,
    .#{$appPrefix}--facet-filters__clear {
      display: block;
      height: 100%;
      background: $button-secondary;
      color: $text-on-color;
      padding: 1rem;
      font-size: 1rem;
      line-height: 1rem;
      &:hover {
        background: $button-secondary-hover;
        text-decoration: none;
        color: $text-on-color;
      }
    }

    // Make Clear all filters button grow to occupy the whole area
    .sk-reset-filters,
    .sk-reset-filters.is-disabled,
    .#{$appPrefix}--facet-filters__clear.is-disabled {
      display: block;
      height: 100%;
    }
    // Gray out Clear all filters if disabled
    .sk-reset-filters.is-disabled a,
    .#{$appPrefix}--facet-filters__clear.is-disabled {
      background: $text-disabled;
      color: $layer-selected-disabled;
      pointer-events: none;
    }
  }

  // Buttons row
  &__buttons.#{$rowPrefix}--col-md-5.#{$rowPrefix}--col-sm-4 {
    display: flex;
    padding: 0;
    > div {
      width: 50%;
    }
    // Make carbon button grow to occupy the whole area
    .#{config.$prefix}--btn {
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }

  // Modal opened
  &__modal.is-open {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $layer-02;
    z-index: 999999;
    .#{$appPrefix}--filters-overlay__content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 3rem;
      padding: 2rem 1rem 1rem;
      overflow: auto;
    }
    // Hide Filter by and Clear all filters when modal is opened
    .#{$appPrefix}--filters__header {
      display: none;
    }
  }

  // Modal closed
  &__modal:not(.is-open) {
    display: none;
  }

  // Remove extra padding in column (div) due to nested grid
  &__content > .#{$rowPrefix}--row > div,
  &__footer > .#{$rowPrefix}--row > div {
    padding-left: 0;
    padding-right: 0;
  }

  // Medium & large breakpoints
  @include grid.breakpoint('md') {
    // Add padding to footer
    &__footer {
      min-height: 5rem;
      border-top: 1px solid $layer-accent-01;
      padding: 1rem;
    }
    &__buttons > div {
      padding-right: 1rem;
    }
    // Footer is now 5rem tall
    &__modal.is-open .#{$appPrefix}--filters-overlay__content {
      bottom: 5rem;
    }
  }

  // Disable filters overlay in large breakpoints
  @include grid.breakpoint('lg') {
    // Hide Filter by link
    &__link,
    // Hide header
    &__header.#{$rowPrefix}--col-sm-4,
    // Hide footer
    &__footer {
      display: none;
    }
    // Disable modal
    &__modal.is-open,
    &__content {
      position: static !important;
      padding: 0 !important;
    }
    // Show content even if modal is closed
    &__modal:not(.is-open) {
      display: block
    }
    // Remove extra margin in row due to nested grid
    &__content > .#{$rowPrefix}--row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// When overlay is open, disable scrollbars in the body
body.#{$appPrefix}--filters-overlay--open {
  overflow: hidden;

  // Remove transform when overlay is open, which breaks fixed positioning
  main.#{$prefix}--content {
    transform: none;
  }
}

// Always enable scrollbars in the body for extra large breakpoints
@include grid.breakpoint('lg') {
  body.#{$appPrefix}--filters-overlay--open {
    overflow: auto;
  }
}

// We don't support filters in the nojs page
.no-js .#{$appPrefix}--filters-overlay {
  display: none;
}
