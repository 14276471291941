//----------------------------------------------
// Dotcom shell customizations
// ---------------------------------------------

body.#{$appPrefix}--search-page {
  background: colors.$gray-10;
}

.#{config.$prefix}--masthead {
  background: $layer-02;
  z-index: 200;
}

.#{$appPrefix}--masthead-wrapper {
  height: 3.0625rem;
}

@media (max-width: 59.9375rem) {
  dds-masthead-menu-button[hide-menu-button] + dds-masthead-logo {
    display: none;
  }

  .#{$appPrefix}--search-page dds-masthead-global-bar {
    padding-right: 0;
  }
}

.#{config.$prefix}--masthead__search {
  flex: 1;
}

.#{config.$prefix}--masthead__search.#{config.$prefix}--masthead__search--active {
  .#{config.$prefix}--header__search--close.#{config.$prefix}--header__action[aria-label='Close'] {
    width: 3rem;
  }
}

@include grid.breakpoint-down('md') {
  .#{$appPrefix}--search-page .#{config.$prefix}--masthead__search.#{config.$prefix}--masthead__search--active {
    right: 0;
  }
}

.#{config.$prefix}--header__search--input,
.#{config.$prefix}--masthead__search--active .bx--header__action {
  background: $layer-02 !important;
}

.#{$bxPrefix}--dotcom-shell {
  position: relative;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;

  &__content {
    min-height: calc(100vh - 3rem);
  }
}

//----------------------------------------------
// Header
// ---------------------------------------------

.#{$appPrefix}--tabs__container {
  padding-top: 0.5rem;

  .#{config.$prefix}--col {
    padding: 0 !important;
  }

  // Medium breakpoint
  @include grid.breakpoint('md') {
    padding-top: 1rem;
  }
}

//----------------------------------------------
// Main content
// ---------------------------------------------

.#{$appPrefix}--content {
  margin-bottom: 3rem;
}

.#{$appPrefix}--hits {
  background: $layer-02;
}

.#{$appPrefix}--content__gutter {
  // Hide gutter in large & down breakpoints
  display: none;

  // Show gutter in extra large breakpoints
  @include grid.breakpoint('xlg') {
    display: block;
  }
}

.#{$appPrefix}--content__sidebar {
  &.#{$appPrefix}--all-tab {
    border-left: none;
  }

  // Hide hits stats from sidebar (small & medium)
  .#{$appPrefix}--hits-stats {
    display: none;
  }
}

.#{$appPrefix}--content__results {
  padding: 1.5rem 1rem 1rem 1rem !important;

  // Hide hits stats
  .#{$appPrefix}--hits-stats {
    display: none;
  }
}

.#{$appPrefix}--content__footer {
  @include grid.breakpoint('md') {
    margin-bottom: 3rem;
  }
}

// Large & up breakpoints
@include grid.breakpoint('lg') {
  .#{$appPrefix}--content__sidebar {
    border-left: 1px solid $layer-accent-01;
    padding-top: 3rem !important;
    padding-bottom: 8rem !important;
    // Show hits stats in sidebar
    .#{$appPrefix}--hits-stats {
      display: block;
    }
  }

  .#{$appPrefix}--content__results {
    padding-top: 2.5rem !important;
    // Hide hits stats from main content
    .#{$appPrefix}--hits-stats {
      display: none;
    }
    // Hide selected filters from main content
    .#{$appPrefix}--filters__selected {
      display: none;
    }
  }
}
