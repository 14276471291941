@charset "UTF-8";

//----------------------------------------------
// Import carbon dependencies
// ---------------------------------------------

// @use '@carbon/react/scss/config' with
//   ($css--default-type: true, $css--reset: true);
// @use '@carbon/grid';
// @use '@carbon/styles/scss/compat/theme';
// @use '@carbon/colors';
// @use '@carbon/type';


//----------------------------------------------
// Search tip styles
// ---------------------------------------------

// $appPrefix: 'ibm';
@import 'search-tips.scss';

// body {
//   margin: 0;
//   padding: 0;
// }

.#{$appPrefix}--tips--nojs {
  background: $layer-01;
  padding: 1rem 1rem 4rem;

  .#{$appPrefix}--tips__content {
    max-width: none;
    padding-top: 0;
  }

  ##{$appPrefix}-tips-title {
    display: block !important;
    @include type.type-scale(8);
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 2rem
  }
}
