//----------------------------------------------
// Full pagination component
// ---------------------------------------------

.#{$appPrefix}--pagination {
  display: flex;
  margin: 0;
  border-top: 1px solid $layer-accent-01;
  padding: 0;
  background: $layer-02;
  align-items: center;

  &.is-disabled {
    display: none;
  }

  &.hide-links {
    &__pagesize,
    &__pagelinks {
      display: none;
    }
  }

  &__pagelinks {
    flex-grow: 4;
    .#{config.$prefix}--pagination-nav {
      float: right;
    }
  }

  .#{$appPrefix}--pagesize {
    border-right: 1px solid $layer-accent-01;

  }
}

//----------------------------------------------
// Pagination links
// ---------------------------------------------

.#{config.$prefix}--pagination-nav {
  &__page--disabled {
    color: $text-disabled !important;
  }

  &__page--ellipsis {
    color: colors.$gray-90 !important;
    line-height: 0;
    svg {
      width: rem(14px);
      height: rem(14px);
    }
  }

  // For small breakpoint, hide page links, only keep next, previous and active page
  &__page:not(&__page--active):not(&__page--direction) {
    display: none;
  }

  // Medium & up breakpoints
  @include grid.breakpoint('md') {
    // Show all page links
    &__page:not(&__page--active):not(&__page--direction) {
      display: block;
    }
  }
}

//----------------------------------------------
// Page size selector
// ---------------------------------------------

.#{$appPrefix}--pagesize {
  // Hide pagesize selector on small breakpoint
  display: none;
  position: relative;
  align-items: center;
  height: 3rem;
  &:after {
    content: $ChevronDownIcon;
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    right: 1rem;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    pointer-events: none;
    fill: $text-primary;
  }

  select {
    // @include type.type-style('body-compact-01');
    // For some reason, body-compact-01 is not working in k8s, so use the properties directly for now:
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    // body-compact-01 ^
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: 2px solid transparent;
    outline-offset: -2px;
    display: block;
    height: 100%;
    border: none;
    border-radius: 0;
    padding: 0 2.5rem 0 1rem;
    background: transparent;
    cursor: pointer;
    color: $text-primary;
    &:hover {
      background: $background-hover;
    }
    &:focus {
      outline-color: $button-primary;
    }
  }

  &__container {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
  }

  // Large breakpoint
  @include grid.breakpoint('lg') {
    // Show pagesize selector on large breakpoints
    display: flex;
  }
}

//----------------------------------------------
// Total results
// ---------------------------------------------

.#{$appPrefix}--pagination__totalresults {
  padding: 0 1rem;
  // @include type.type-style('body-compact-01');
  // For some reason, body-compact-01 is not working in k8s, so use the properties directly for now:
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  // body-compact-01 ^
}

//----------------------------------------------
// Fix for FireFox only: remove letter spacing from select to avoid cropping
// ---------------------------------------------

@-moz-document url-prefix() { 
  .#{$appPrefix}--pagesize,
  .#{$appPrefix}--sort {
    select {
      letter-spacing: 0;
    }
  }
}
