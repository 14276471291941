//----------------------------------------------
// Footer customizations
// ---------------------------------------------

// Banner
.#{$appPrefix}--footer-banner {
  a {
    display: flex;
    align-items: center;
    background: colors.$gray-90;
    border-bottom: rem(3px) solid colors.$teal-40;
    text-decoration: none;
    transition: background-color 100ms cubic-bezier(0.2, 0, 0.38, 0.9);

    span {
      padding: 1rem;
    }

    &:hover {
      background: colors.$gray-100;
    }

    &:focus {
      outline: rem(3px) solid colors.$teal-40;
      outline-offset: rem(-3px);
    }
  }

  .#{$appPrefix}--footer-banner__icon {
    padding: 1rem 0 1rem 0.75rem;

    svg {
      fill: colors.$teal-40;
    }
  }

  .#{$appPrefix}--footer-banner__label {
    @include type.type-style('body-compact-02');
    font-weight: 600;
    color: colors.$white-0;
  }

  .#{$appPrefix}--footer-banner__cta {
    flex: 1;
    padding-left: 0;

    .#{config.$prefix}--btn--tertiary {
      display: none;
    }

    .#{config.$prefix}--arrow-right {
      fill: colors.$white-0;
    }
  }

  @include grid.breakpoint('md') {
    .#{$appPrefix}--footer-banner__icon {
      padding: 1rem 0.75rem 1rem 1.75rem;
    }

    .#{$appPrefix}--footer-banner__label {
      flex: 1;
    }

    .#{$appPrefix}--footer-banner__cta {
      flex: none;
      padding-left: 1rem;

      .#{config.$prefix}--btn--tertiary {
        display: inline-block;
        border-color: colors.$white-0;
        color: colors.$white-0;
        line-height: 1rem;

        svg {
          fill: colors.$white-0;
        }

        &:hover {
          background: transparent;
          color: colors.$white-0;

          svg {
            fill: colors.$white-0;
          }
        }
      }

      .#{config.$prefix}--arrow-right {
        display: none;
      }
    }
  }
}

// Locale selector
.#{config.$prefix}--locale-modal {
  a:hover {
    text-decoration: none;
  }
}
