//----------------------------------------------
// Embedded Products layout
// ---------------------------------------------

.#{$appPrefix}--embedded-products {
  background: #fff;

  .#{config.$prefix}--dotcom-shell {
    padding-top: 0;
  }

  //----------------------------------------------
  // Leadspace (Title and search bar)
  // ---------------------------------------------

  .#{$appPrefix}--searchbar {
    border-bottom: 1px solid $layer-active-01;
    padding: 3rem 0;

    &__heading {
      padding-bottom: 2rem;

      h1 {
        @include type.type-style('fluid-heading-06', true);
      }
    }
  }

  .sk-search-box {
    margin: 0;
    width: 100%;

    &__action {
      left: 1rem;
      padding: 0;
      text-align: left;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: #161616;
      }
    }

    &__clear {
      right: 0;
      width: 3rem;
      overflow: hidden;

      a {
        margin-left: -2px;
        width: calc(3rem + 2px);
        height: 3rem;
        color: currentColor;
        border-bottom: 1px solid $toggle-off;

        &:hover {
          background: $ui-03;
        }
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
      }
    }
  }

  #ibm-search-input:focus ~ .sk-search-box__clear a {
    border-bottom-color: $button-primary;

    &:hover {
      outline: 2px solid $button-primary;
      outline-offset: -2px;
    }
  }

  //----------------------------------------------
  // Searchbox inside content area
  // ---------------------------------------------

  .#{$appPrefix}--embed--searchbox {
    margin: 0 0 1rem 0;
    @include carbon--breakpoint('lg') {
      margin: 0 -1rem 1rem -1rem;
    }

    .sk-search-box {
      @include carbon--breakpoint('md') {
        margin: 0;
        width: 100%;
      }

      @include carbon--breakpoint('lg') {
        margin: 0;
        width: 100%;
      }
    }
  }

  #ibm-search-input {
    border: none;
    border-bottom: 1px solid $toggle-off;
    background: $layer-01;
    padding: 0 3rem 0 3.5rem;
  }

  @include grid.breakpoint('md') {
    .sk-search-box {
      margin: 0 1rem;
      width: calc(100% - 2rem);
    }
  }

  @include grid.breakpoint('lg') {
    .#{$appPrefix}--searchbar {
      padding: 4rem 0 6rem;

      &__heading {
        padding-bottom: 3rem;
      }
    }

    #ibm-search-input {
      height: 4rem;
      padding: 0 2rem 0 4rem;
      line-height: 3.875rem;
      font-size: 1.25rem;
    }

    .sk-search-box {
      width: 83.33%;
      margin-left: 0;

      &__action {
        svg {
          width: 2rem;
          height: 2rem;
        }
      }

      &__clear {
        a {
          height: 4rem;
          line-height: 4rem;
        }
      }
    }
  }

  //----------------------------------------------
  // Alternate header (with searchbox and page size)
  // ---------------------------------------------

  .#{$appPrefix}--searchhdr {
    margin-bottom: 1rem;

    .sk-search-box {
      margin: 0;
    }

    .#{$appPrefix}--pagesize-top {
      margin-top: 1rem;

      &.is-disabled {
        display: none;
      }
    }

    @include carbon--breakpoint('md') {
      .#{$appPrefix}--pagesize__title,
      .#{$appPrefix}--pagesize {
        display: block;
      }
    }

    @include carbon--breakpoint('lg') {
      margin-bottom: 2rem;
      display: flex;
      align-items: stretch;

      &__searchbox {
        flex: 2 1;

        .sk-search-box {
          width: calc(100% + 1rem);
          margin-left: -1rem;
        }
      }

      &__pagesize {
        flex: 1 1;
      }

      .#{$appPrefix}--pagesize-top {
        margin-top: 0;
        margin-left: 0;
        height: 100%;
      }
    }
  }

  //----------------------------------------------
  // Sidebar (Filters)
  // ---------------------------------------------

  $hierarchicalOptions: 'sk-hierarchical-refinement-list__hierarchical-options';

  .#{$appPrefix}--content__sidebar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .#{$appPrefix}--filters__header {
    display: none;
  }

  .sk-panel,
  .sk-hierarchical-refinement-list {
    border-top: none !important;
  }

  .sk-panel__header,
  .sk-hierarchical-refinement-list__header,
  .#{$appPrefix}--facet-filters__title,
  .sk-item-list-option,
  .sk-refinement-list__view-more-action,
  .sk-hierarchical-refinement-list__view-more-action,
  .#{$hierarchicalOptions} a {
    padding-left: 2.5rem;
  }

  // Extra padding on the left for nested filters
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-left: 3.5rem;
  }
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-left: 4.5rem;
  }
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-left: 5.5rem;
  }
  .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} .#{$hierarchicalOptions} a {
    padding-left: 6.5rem;
  }

  // Filter by link
  .#{$appPrefix}--filters-overlay {
    &__link {
      width: 100%;
      margin: -0.5rem 0 2rem;
      border-bottom: 1px solid $toggle-off;
      background: $layer-02;
      line-height: 2rem;
    }

    &__link-label {
      padding: 0 1rem;
    }

    &__link-icon {
      margin-top: 0.45rem;
    }
  }

  @include grid.breakpoint('lg') {
    ##{$appPrefix}--filters {
      margin: 0 1rem 0 0;
      height: 100%;
      padding: 3rem 0;
      border-right: 1px solid $ui-03;
    }
  }

  @include grid.breakpoint('xlg') {
    ##{$appPrefix}--filters {
      margin: 0;
    }
  }

  //----------------------------------------------
  // Collapsible filters
  // ---------------------------------------------

  .#{$appPrefix}--collapsible-filters {
    .#{config.$prefix}--accordion__title {
      font-size: 1rem;
      font-weight: 600;
      .sb-tooltip-trigger {
        border: none;
        background: none;
        vertical-align: middle;
      }
    }

    .#{config.$prefix}--accordion__content {
      padding: 0 0 2em 0 !important;
    }

    .sk-panel,
    .sk-hierarchical-refinement-list {
      padding-top: 0;
      margin: 0;
    }

    .#{$appPrefix}--empty-filter,
    .sk-panel__header,
    .sk-hierarchical-refinement-list__header {
      display: none;
    }

    .sk-item-list-option,
    .sk-refinement-list__view-more-action,
    .sk-hierarchical-refinement-list__view-more-action,
    .sk-hierarchical-refinement-list__hierarchical-options a {
      padding-left: 1rem;
    }

    .sk-hierarchical-refinement-list__hierarchical-options .sk-hierarchical-refinement-list__hierarchical-options a {
      padding-left: 2rem;
    }

    .sk-hierarchical-refinement-list__hierarchical-options .sk-hierarchical-refinement-list__hierarchical-options .sk-hierarchical-refinement-list__hierarchical-options a {
      padding-left: 3rem;
    }

    .sk-hierarchical-refinement-list__hierarchical-options .sk-hierarchical-refinement-list__hierarchical-options .sk-hierarchical-refinement-list__hierarchical-options .sk-hierarchical-refinement-list__hierarchical-options a {
      padding-left: 4rem;
    }

    .#{$appPrefix}--filters-overlay__modal.is-open {
      background: transparent;
    }

    .#{$appPrefix}--filters-overlay__header {
      font-size: 1rem;
      font-weight: 600;
      line-height: 4rem;
      padding: 0 1rem !important;
    }

    .#{$appPrefix}--filters-overlay__close {
      display: none;
    }

    .#{$appPrefix}--filters-overlay__link {
      border: 1px solid #0f62fe;
    }

    .#{$appPrefix}--filters-overlay__link-label {
      color: #0f62fe;
    }

    @media (max-width: 1055px) {
      .#{$appPrefix}--filters-overlay__modal.is-open {
        background: rgba(0,0,0,0.5);
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__wrapper {
        position: absolute;
        background: #f4f4f4;
        top: 2rem;
        bottom: 2rem;
        left: calc(50% - 210px);
        width: 420px;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__header {
        top: 0;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__close {
        display: block;
        position: absolute;
        right: 1rem;
        top: 1.45rem;
        cursor: pointer;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__content {
        top: 4rem;
        bottom: 4rem;
        padding: 0 0 3rem 0;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__footer {
        padding: 0;
        height: 3rem;
        min-height: 3rem;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__buttons {
        display: flex;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__buttons > div {
        flex: 1;
        padding: 0;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .sk-reset-filters__reset {
        line-height: 1rem;
      }

      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__buttons button.#{$prefix}--btn {
        line-height: 1rem;
        width: 100%;
      }
    }

    @media (max-width: 420px) {
      .#{$appPrefix}--filters-overlay__modal.is-open .#{$appPrefix}--filters-overlay__wrapper {
        position: absolute;
        background: #f4f4f4;
        top: 0rem;
        bottom: 0rem;
        left: 0;
        right: 0;
        width: auto;
      }
    }

    .sk-hierarchical-refinement-list__hierarchical-options a.is-selected.is-leaf:after {
      right: 1rem;
    }

    .sk-item-list-option:hover,
    .sk-hierarchical-refinement-list__hierarchical-options a:hover {
      background: #e5e5e5;
    }

    .sk-item-list-option.is-active,
    .sk-hierarchical-refinement-list__hierarchical-options a.is-selected.is-leaf {
      background: #d0e2ff;
    }

    .sk-item-list-option.is-active:hover,
    .sk-hierarchical-refinement-list__hierarchical-options a.is-selected.is-leaf:hover {
      background: #a6c8ff;
    }
  }

  //----------------------------------------------
  // Results
  // ---------------------------------------------

  .#{$appPrefix}--content__results {
    padding-top: 3rem !important;

    ##{$appPrefix}-hits-wrapper {
      clear: both;
    }

    .#{$appPrefix}--filters__selected {
      margin-top: 2rem;

      @include carbon--breakpoint('lg') {
        display: block;
        margin: -0.25rem 0 0 -2rem;
      }

      &::after {
        display: block;
        clear: both;
      }

      .sk-selected-filters {
        margin-left: -0.25rem;

        > a {
          margin: 0.25rem;

          &:last-child {
            margin-right: 0.75rem;
          }
        }

        + .sk-reset-filters {
          margin-left: 0.25rem;
        }
      }

      .sk-reset-filters {
        margin-bottom: 1.5rem;
      }

      .sk-reset-filters__reset {
        padding: 0.375rem 0;
        line-height: 1.5rem;
      }

      .#{$prefix}--tag {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.16px;
        display: inline-block;
        position: relative;
        height: 2rem;
        margin: 0;
        padding: 0.375rem 2.375rem 0.375rem 1rem;
        background-color: #d0e2ff;
        color: #0043ce;
        cursor: pointer;

        // Do not display the filter "type" on the pills
        .filter-type {
          display: none;
        }

        &:after {
          position: absolute;
          content: url("data:image/svg+xml; utf8, <svg focusable='false' preserveAspectRatio='xMidYMid meet' style='will-change: transform;' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' aria-hidden='true'><path fill='rgba(0,67,206,100)' d='M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8z'></path></svg>");
          margin-left: 0;
          margin-top: 0;
          right: 0.5rem;
          top: 0.5rem;
        }

        &:hover {
          background-color: #a6c8ff;
        }
      }
    }
  }

  //----------------------------------------------
  // Results - Cards
  // ---------------------------------------------

  a.#{config.$prefix}--card:hover {
    text-decoration: none;
  }

  .#{$prefix}--card {
    position: relative;;
    padding: 0;

    .#{$prefix}--image {
      &::before {
        background-color: transparent;
      }
    }

    .#{$prefix}--card__eyebrow {
      font-size: 14px !important;
    }

    &:hover {
      text-decoration: none;
    }

    &:focus,
    &:focus-within {
      outline-offset: -1px;
    }
  }

  ##{$appPrefix}-hits-wrapper .#{$prefix}--card-group__cards__col p {
    height: auto;
  }

  .bx--card-group__cards__col .bx--card-group__card {
    display: block;
  }

  //----------------------------------------------
  // Cards with gap
  // ---------------------------------------------
  .#{$appPrefix}-cards-group-with-gap {
    .#{$prefix}--card-group__cards__row {
      margin: 0.5rem -0.5rem;
    }

    .#{$prefix}--card-group__cards__col {
      padding: 0.5rem;
      border: 0;
      background: transparent;
      .#{$appPrefix}--card-images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(6rem, auto);
        margin: 1rem 1rem 0.5rem;
        .#{$appPrefix}--card-image-wrapper {
          position: relative;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: contain;
            object-position: left center;
            top: 0;
          }
          &:first-child img {
            left: 0;
          }
          &:last-child img {
            right: 0;
            object-position: right center;
          }
        }
      }

      .#{$appPrefix}--card-tags {
        width: 100%;
        margin-bottom: 1rem;
      }

      .#{$appPrefix}--alt__cta {
        color: var(--cds-text-01,#161616);
      }
    }

    @include carbon--breakpoint('md') {
      .#{$prefix}--card-group__cards__col {
        height: 100%;
        border-bottom: 0 !important;
        // max-width: 370px; // this is because the test data is weird
        .#{$prefix}--card-group__card__wrapper {
          height: 100%;
        }
      }
    }

    @include carbon--breakpoint('lg') {
      .#{$prefix}--card-group__cards__row {
        margin: -0.5rem -1.5rem;
      }
    }
  }

  //----------------------------------------------
  // Card images (icons)
  // ---------------------------------------------
  .#{$prefix}--card-group__card .#{$prefix}--image {
    position: relative;
    padding-top: 75%;
    height: 0;
    overflow: hidden;
    background: #fff;
    margin: 1px;

    picture {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__img {
      position: static;
      width: 216px;
      height: 162px;
      object-fit: contain;
    }
  }

  //----------------------------------------------
  // Compact card design
  // ---------------------------------------------

  .card__compact {
    .#{$prefix}--card {
      border: 1px solid $ui-03;
      background: $ui-background;

      &:hover {
        border-color: $ui-04;

        .#{$prefix}--card__wrapper {
          background: $ui-background;
        }
      }

      .#{$appPrefix}--card__inner{
        margin-bottom: 1rem;
      }

      .#{$prefix}--card__heading {
        margin-bottom: 1.5rem;
        overflow-wrap: anywhere;
      }

      .#{$prefix}--card__copy {
        margin-bottom: 0.5rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;

        .#{$appPrefix}--card__copy__inner {
          margin-top: 30px;
        }
      }

      .#{$prefix}--card__footer .#{$prefix}--card__cta {
        display: none;
      }
    }

    .#{$prefix}--card-group__card {
      .#{$prefix}--image {
        padding-top: 6.5rem;

        &__img {
          width: 10rem;
          height: 5rem;
        }

        picture {
          width: 10rem;
          height: 5rem;
          left: 1rem;
          top: 1rem;
          justify-content: flex-start;
          overflow: hidden;
        }
      }

      &.with-tags .#{$prefix}--card__cta__copy {
        margin: 0 0 0.5rem -0.25rem;
      }

      .#{$prefix}--tag {
        height: 1.5rem;
        padding: 6px 0.5rem;
      }
    }
  }

  //----------------------------------------------
  // Results - Horizontal media item
  // ---------------------------------------------

  .#{$appPrefix}--horizontal-media-group {
    margin: 0 -1rem;
  }

  .#{$appPrefix}--horizontal-media-row {
    display: block;
    border-top: 1px solid $ui-03;
    margin-top: 1rem;
    color: $text-02;

    > div {
      padding: 1rem 1rem 0;
    }

    &__meta {
      @include type.type-style('body-short-01', true);
    }

    &__format,
    &__tags {
      margin-top: 0.5rem;
    }

    .cds--tag:first-child {
      margin-left: 0;
    }

    &__content {
      p {
        @include type.type-style('body-short-02', true);
      }
    }

    &__eyebrow {
      font-size: 0.875rem;
      margin-bottom: 0.25rem;
    }

    &__heading {
      margin-bottom: 1rem;
      @include type.type-style('expressive-heading-03', true);
      color: $link-primary;
    }

    &__labels {
      margin-top: 1.5rem;
      @include type.type-style('label-01', true);

      span:not(:first-child) {
        &::before {
          content: ' | ';
          display: inline-block;
          margin: 0 0.5rem;
        } 
      }
    }

    &__image-wrapper {
      width: 13rem;
      height: auto;
      aspect-ratio: 16 / 9;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
        object-position: center;
      }
    }

    &:hover {
      color: $text-primary;
      text-decoration: none;

      .#{$appPrefix}--horizontal-media-row__heading {
        color: $link-primary-hover;
        text-decoration: underline;
      }
    }
  }

  @include carbon--breakpoint('lg') {
    .#{$appPrefix}--horizontal-media-row {
      display: flex;

      &__meta {
        flex: 0 0 16.67%;
      }
  
      &__content {
        flex: 0 0 50%;
      }
  
      &__media {
        flex: 0 0 33.33%;
      }
  
      &__image-wrapper {
        width: 10rem;
      }
    }
  }

  @include carbon--breakpoint('xlg') {
    .#{$appPrefix}--horizontal-media-row {
      &__image-wrapper {
        width: 13rem;
      }
    }
  }

  @include carbon--breakpoint('max') {
    .#{$appPrefix}--horizontal-media-row {
      &__image-wrapper {
        width: 16rem;
      }
    }
  }

  .#{$prefix}--tooltip__label {
    vertical-align: middle;
    margin-left: 0.25rem;
  }

  //----------------------------------------------
  // No hits
  // ---------------------------------------------

  .#{$appPrefix}--no-hits {
    h1 {
      @include carbon--type-style('expressive-heading-04', true);
      margin: 0 0 3rem;
      padding: 0;

      @include carbon--breakpoint('lg') {
        margin: 2rem 0 3rem;
      }
    }

    h2 {
      @include carbon--type-style('expressive-heading-02', true);
      margin-bottom: 1rem;
    }

    &__tips {
      margin-bottom: 3rem;
      max-width: 40rem;
      padding: 1rem 2rem 1rem 1rem;
      background: $ui-01;

      ul {
        @include carbon--breakpoint('md') {
          column-count: 2;
          column-gap: 2rem;
        }

        li {
          margin-left: 1.75rem;
          padding-bottom: 0.25rem;
          line-height: 1.5rem;

          &::before {
            content: url("data:image/svg+xml; utf8, <svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='20' height='20' viewBox='0 0 20 20' aria-hidden='true'><path stroke-width='0.5' d='M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z'></path></svg>");
            display: inline-block;
            text-indent: -1.75rem;
            vertical-align: middle;
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }

  .#{$prefix}--skeleton__text:before {
    width: 100%;
  }

  .#{$appPrefix}--pagination {
    background: #f4f4f4;
    margin: 1rem -1rem 0;

    @include carbon--breakpoint('md') {
      margin: 1rem 0 0;
    }

    @include carbon--breakpoint('lg') {
      margin: 1rem -1rem 0;
    }
  }

  // g2Crowd widget
  .#{$appPrefix}--g2crowd {
    padding-top: 0.25rem;
    padding-bottom: 0;
  }
}

##{$appPrefix}-hits-wrapper {
  margin-top: 2rem;
}

//----------------------------------------------
// Common Header in embedded widget
// ---------------------------------------------

.#{$appPrefix}--embedhdr {
  display: flex;
  align-items: center;
  margin: 0 0 1rem 1rem;

  @include carbon--breakpoint('lg') {
    margin: 0 -1rem 1rem 0;
  }

  &__summary {
    flex-grow: 4;
  }

  .#{$appPrefix}--totalresults {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
  }

  &__pagesize,
  &__sort {
    select {
      height: auto;
      border: 0;
      border-bottom: 1px solid #8d8d8d;
      padding: 0.5rem 3rem 0.5rem 1rem;
      line-height: 1.5rem;

      &:hover {
        cursor: pointer;
        background: #e8e8e8;
      }
    }
  }
}

.#{$appPrefix}--embedhdr__sort {
  margin-left: 1rem;
}

//----------------------------------------------
// Partner showcase specific CSS rules
// ---------------------------------------------

.partner-explorer-container {
  .tileContainer {
    padding: 0;
  }

  .fluidcontainer {
    display: block;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}

// When loading overlay is open, remove transform to properly center spinner
body.#{$appPrefix}--loading-overlay--open {
  main.#{$prefix}--content {
    transform: none;
  }
}

//----------------------------------------------
// Right to Left (RTL) support
// ---------------------------------------------

[dir="rtl"] .#{$appPrefix}--embedded-products {
  /* Search box */
  ##{$appPrefix}-search-input {
    padding: 0 3.5rem 0 3rem;

    &:focus ~ .sk-search-box__clear a {
      margin-left: auto;
      margin-right: -2px;
    }
  }

  .sk-search-box__action {
    left: auto;
    right: 1rem;
    text-align: right;
  }

  .sk-search-box__clear {
    left: 0;
    right: auto;
  }

  /* Header */
  @media (min-width: 66rem) {
    .#{$appPrefix}--embedhdr {
      margin: 0 0 1rem -1rem;
    }
  }

  .#{$appPrefix}--pagesize {
    margin: 0;
    border: 0;
  }

  .#{$appPrefix}--pagesize select {
    padding: .5rem 1rem .5rem 3rem;
  }

  .#{$appPrefix}--embedhdr__sort {
    margin-left: 0;
    margin-right: 1rem;
  }

  .#{$appPrefix}--embedhdr__sort select {
    padding: .5rem 1rem .5rem 3rem;
  }

  .#{$appPrefix}--sort .bx--select__arrow {
    right: auto;
    left: 1rem;
  }

  /* Filters */
  @media (min-width: 66rem) {
    ##{$appPrefix}--filters {
      margin: 0 0 0 1rem;
      border-right: 0;
      border-left: 1px solid var(--cds-ui-03, #e0e0e0);
    }
  }

  .cds--accordion__arrow {
    margin: 2px 0 0 1rem;
  }

  .#{$appPrefix}--collapsible-filters .sk-hierarchical-refinement-list__hierarchical-options a.is-selected.is-leaf:after {
    right: auto;
    left: 1rem;
  }

  .sk-selected-filters a .cds--tag:after {
    margin-left: 0;
    margin-right: .25rem;
  }
}
